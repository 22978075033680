body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.bg-primary:hover {
    background-color: #1f94bd !important;
}

.bg-success:hover {
  background-color: #3ea662 !important;
}

.bg-info:hover {
  background-color: #40ACCB !important;
}

.bg-warning:hover {
  background-color: #E4AB00 !important;
}

.bg-danger:hover {
  background-color: #ff2b2b !important;
}

.bg-gray-500:hover {
  background-color: #73818f !important;
}

.noDecoLink:hover {
  text-decoration: none;
}

.csvDownload{
    visibility: hidden;
}

.draft {
    border-top-color: #63c2de !important;
    border-top-width: 2px !important;
}

a.draft {
    color: #63c2de !important;
}

.live {
    border-top-color: #4dbd74 !important;
    border-top-width: 2px !important;
}

a.live {
    color: #4dbd74 !important;
}

.maintenance {
    border-top-color: #ffc107 !important;
    border-top-width: 2px !important;
}

a.maintenance {
    color: #ffc107 !important;
}

.inactive {
    border-top-color: #f86c6b !important;
    border-top-width: 2px !important;
}

a.inactive {
    color: #f86c6b !important;
}

.declined {
    border-top-color: #2f353a !important;
    border-top-width: 2px !important;
}

a.declined {
    color: #2f353a !important;
}

a.coming_soon {
    color: #676d72 !important;
}

.react-datetime-picker__button {
  border: none !important;
}

.row.row-grid [class*="col-"] + [class*="col-"] {
    margin-top: 15px;
}

@media (min-width: 1200px) {
    .row.row-grid [class*="col-lg-"] + [class*="col-lg-"] {
        margin-top: 0;
    }
}
@media (min-width: 992px) {
    .row.row-grid [class*="col-md-"] + [class*="col-md-"] {
        margin-top: 0;
    }
}
@media (min-width: 768px) {
    .row.row-grid [class*="col-sm-"] + [class*="col-sm-"] {
        margin-top: 0;
    }
}

.row-grid + .row-grid {
    margin-top: 15px;
}

.dropUpload {
    border: 5px dashed #c8ced3;
    width: 100%;
    color: black;
    padding: 20px;
    height: 300px;
}

.Blink {
    animation: blinker 0.8s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

.filedrop {
    border: 5px dashed #f0f4f5;
    padding: 40px;
    color: #3F3F40;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px
}

.tooltip { pointer-events: none; }

.table > tbody > tr > td {
    vertical-align: middle;
}

@keyframes blinker {
    from { opacity: 1; }
    to { opacity: 0; }
}

.userType {
    padding: 6px;
    border: 1px solid #c8ced3;
    border-radius: 0.25rem;
}